<template>
  <div class="max-width">
    <div class="sro-item">
      <error-message v-if="errorMessage">
        {{ errorMessage }}
      </error-message>
      <success-message v-if="successMessage">
        {{ successMessage }}
      </success-message>
      <spinner-component
        v-if="!statement && !errorMessage && spinnerIsShow"
      />
      <div v-if="statement">
        <div
          class="sro-item__info"
          style="margin-top:20px"
        >
          <div style="font-size:1.2em" v-if="statement">Информация о выписке</div>
          <div class="sro-item__row">
            <div class="sro-item__row_title">Полное наименование:</div>
            <div class="sro-item__row_value">{{ statement.member.full_description }}</div>
          </div>
          <div class="sro-item__row">
            <div class="sro-item__row_title">ИНН:</div>
            <div class="sro-item__row_value">{{ statement.member.inn }}</div>
          </div>
          <div class="sro-item__row">
            <div class="sro-item__row_title">Кому выдана:</div>
            <div class="sro-item__row_value">{{ statement.recipient }}</div>
          </div>
          <div class="sro-item__row">
            <div class="sro-item__row_title">Дата выдачи:</div>
            <div class="sro-item__row_value">{{ formatDate(statement.dttm_created) }}</div>
          </div>
          <div class="sro-item__row">
            <div class="sro-item__row_value">
              <div style="margin-left:-20px;">
                <v-btn
                  class="btn"
                  color="secondary"
                  @click="downloadStatement('pdf')"
                >
                  <div class="d-flex justify-center align-center">
                    <v-icon style="font-size: 20px">
                      mdi-download
                    </v-icon>
                    <span>Скачать документ</span>
                  </div>
                </v-btn>
                <v-btn
                  class="btn"
                  color="secondary"
                  @click="downloadStatement('sig')"
                >
                  <div class="d-flex justify-center align-center">
                    <v-icon style="font-size: 20px">
                      mdi-download
                    </v-icon>
                    <span>Скачать подпись НОСТРОЙ</span>
                  </div>
                </v-btn>
                <v-btn
                  class="btn"
                  color="secondary"
                  @click="downloadStatement('sig_sro')"
                  v-if="statement.extract_type==='sro'"
                >
                  <div class="d-flex justify-center align-center">
                    <v-icon style="font-size: 20px">
                      mdi-download
                    </v-icon>
                    <span>Скачать подпись СРО</span>
                  </div>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  downloadStatementQrCode,
  getStatementItem
} from '../../helpers/api'
import { formatDate } from '../../helpers/format'
import ErrorMessage from '../../components/alerts/ErrorMessage'
import SuccessMessage from '../../components/alerts/SuccessMessage'
import SpinnerComponent from '../../components/spinner/Spinner'
import ValidationMixin from '../../mixins/ValidationMixin'
import { downloadFileFromArrayBuffer } from '../../helpers/helper'

export default {
  name: 'StatementItem',
  components: { ErrorMessage, SpinnerComponent, SuccessMessage },
  mixins: [ValidationMixin],
  data: () => ({
    statement: null,
    errorMessage: '',
    successMessage: '',
    spinnerIsShow: false
  }),
  methods: {
    async downloadStatement (file) {
      this.statementSpinnerIsShow = true
      const responseData = await downloadStatementQrCode(this.statement.token, file)
      if (!responseData.success) {
        this.errorMessage = responseData.message
        this.statementSpinnerIsShow = false
        return
      }

      this.statementSpinnerIsShow = false
      let ext = ''
      if (file === 'sig') {
        ext = '.sig'
      } else if (file === 'sig_sro') {
        ext = '_sig_sro.sig'
      } else if (file === 'pdf') {
        ext = '.pdf'
      } else {
        ext = ''
      }
      const dateDoc = this.statement.dttm_created.substring(0, 10).replace(/-/gi, '_')
      const fileName = this.statement.member.inn + '_' + dateDoc + ext
      downloadFileFromArrayBuffer(responseData.data, fileName, responseData.contentType)
      this.errorMessage = ''
    },
    async getStatement (token) {
      this.errorMessage = ''
      this.spinnerIsShow = true
      const requestData = await getStatementItem(token)
      this.spinnerIsShow = false

      if (!this.validateRequestData(requestData)) {
        return
      }
      this.statement = requestData.data
    },
    formatDate (dateTime) {
      if (!dateTime) return
      return formatDate(new Date(dateTime))
    },
    showError (message) {
      this.errorMessage = message
    }
  },
  async mounted () {
    await this.getStatement(this.$route.params.token)
  }
}
</script>

<style scoped>

</style>
